import React from 'react';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import {Box, Container, Grid, IconButton, Typography} from '@mui/material';

const SiteFooter = (): React.JSX.Element => {
  return (
    <Box position='static' component='footer' sx={{backgroundColor: '#FFC107', py: 3, px: 2, mt: 'auto', bottom: 0}}>
      <Container maxWidth='lg'>
        <Grid container spacing={0} alignItems='center'>
          <Grid item xs={12} md={6}>
            <Typography variant='body1' sx={{textAlign: {xs: 'center', md: 'left'}}} color='primary.main'>
              Desarrollado con <Typography component='span' color='#D32F2F'>&#10084;</Typography> por <a href='https://cristiammercado.com' target='_blank' rel='noopener' title='Ir a la web de Cristiam Mercado' className={'DarkLink'}>Cristiam Mercado</a>
            </Typography>
            <Typography variant='body2' color='primary.main' sx={{textAlign: {xs: 'center', md: 'left'}, mb: {xs: 2, md: 0}, mt: {xs: 1, md: 0}}}>
              Copyright &copy; {new Date().getFullYear()}. Golito. Todos los derechos reservados.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2} alignItems='center' sx={{justifyContent: {xs: 'center', md: 'flex-end'}}}>
              <Grid item xs={2} md={1} sx={{textAlign: {xs: 'center'}}}>
                <IconButton component='a' size='large' href='https://facebook.com/golitoco' target='_blank' rel='nofollow noreferrer noopener' title='Ir a la página de Facebook' sx={{color: '#1877F2'}}><FacebookIcon/></IconButton>
              </Grid>
              <Grid item xs={2} md={1} sx={{textAlign: {xs: 'center'}}}>
                <IconButton component='a' size='large' href='https://instagram.com/golitoco' target='_blank' rel='nofollow noreferrer noopener' title='Ir a la página de Instagram' sx={{color: '#F0000D'}}><InstagramIcon/></IconButton>
              </Grid>
              <Grid item xs={2} md={1} sx={{textAlign: {xs: 'center'}}}>
                <IconButton component='a' size='large' href='https://twitter.com/golitoco' target='_blank' rel='nofollow noreferrer noopener' title='Ir a la cuenta de Twitter' sx={{color: '#1D9BF0'}}><TwitterIcon/></IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

      </Container>
    </Box>
  );
};

export default SiteFooter;
