import React from 'react';
import {Box, Fab, useScrollTrigger, Zoom} from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const ScrollToTop = (): React.JSX.Element => {

  const trigger: boolean = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event: React.MouseEvent<HTMLDivElement>): void => {
    const anchor: Element | null = ((event.target as HTMLDivElement).ownerDocument || document).querySelector('#app-top');
    if (anchor) anchor.scrollIntoView({behavior: 'smooth', block: 'center'});
  };

  return (
    <Zoom in={trigger}>
      <Box onClick={handleClick} sx={{position: 'fixed', bottom: {xs: 225, sm: 225, md: 120}, right: 22}}>
        <Fab color='secondary' size='medium' data-umami-event='scroll-to-top-btn'>
          <KeyboardArrowUpIcon/>
        </Fab>
      </Box>
    </Zoom>
  )
}

export default ScrollToTop;
