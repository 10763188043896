import Image from 'next/image';
import MenuIcon from '@mui/icons-material/Menu';
import React, {MouseEvent, useState} from 'react';
import {AppBar, Box, Button, Container, Divider, IconButton, Menu, MenuItem, Toolbar, Typography} from '@mui/material';

import logoToolbarImg from '../../../public/img/logo-toolbar.png';

const SiteNavbar = (): React.JSX.Element => {
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);

  const handleOpenNavMenu = (event: MouseEvent<HTMLElement>) => setAnchorElement(event.currentTarget);
  const handleCloseNavMenu = () => setAnchorElement(null);

  return (
    <AppBar position='sticky' enableColorOnDark sx={{backgroundColor: '#FFC107'}}>
      <Container maxWidth='lg' disableGutters>
        <Toolbar disableGutters>
          <Box sx={{ml: 3, mr: 3, flexGrow: 1, display: {xs: 'none', sm: 'flex'}}}>
            <Typography component='a' href='/' title='Ir al inicio'>
              <Image src={logoToolbarImg} alt='Logo de Golito' title='Golito' placeholder='blur'/>
            </Typography>
            <Box sx={{flexGrow: 1}}/>
            <Button component={'a'} href='/contacto' sx={{ml: 1, fontWeight: 700}} title='Contáctanos'>Contacto</Button>
            <Button component={'a'} href='/politica-privacidad' sx={{ml: 1, fontWeight: 700}} title='Leer la política de tratamiento de datos personales'>Privacidad</Button>
            <Button component={'a'} href='/terminos-y-condiciones' sx={{ml: 1, fontWeight: 700}} title='Leer los términos y condiciones'>Términos</Button>
            <Button component={'a'} color='primary' variant='contained' href='https://app.golito.co' rel='noopener' sx={{ml: 2, fontWeight: 500, height: 40, top: 6}} title='Acceder a la aplicación'>Acceder</Button>
          </Box>
          <Box sx={{ml: 2, mr: 2, flexGrow: 1, display: {xs: 'flex', sm: 'none'}}}>
            <Box sx={{display: 'flex'}}>
              <Typography component='a' href='/' title='Ir al inicio' sx={{mb: '-6px'}}>
                <Image src={logoToolbarImg} alt='Logo de Golito' title='Golito' placeholder='blur'/>
              </Typography>
            </Box>
            <Box sx={{flexGrow: 1}}/>
            <IconButton size='large' color='inherit' onClick={handleOpenNavMenu} title='Menú'>
              <MenuIcon sx={{color: '#1F1F1F'}}/>
            </IconButton>
            <Menu id={'menu-appbar'} anchorEl={anchorElement} anchorOrigin={{vertical: 'bottom', horizontal: 'right'}} transformOrigin={{vertical: 'top', horizontal: 'right'}} sx={{display: {xs: 'block', md: 'none'}}} open={Boolean(anchorElement)} onClose={handleCloseNavMenu} keepMounted>
              <MenuItem component={'a'} href='https://app.golito.co' rel='noopener' title='Acceder a la aplicación'>
                <Typography textAlign='center'>Acceder</Typography>
              </MenuItem>
              <Divider/>
              <MenuItem component={'a'} href='/contacto' title='Contáctanos'>
                <Typography textAlign='center'>Contacto</Typography>
              </MenuItem>
              <MenuItem component={'a'} href='/politica-privacidad' title='Leer la política de tratamiento de datos personales'>
                <Typography textAlign='center'>Privacidad</Typography>
              </MenuItem>
              <MenuItem component={'a'} href='/terminos-y-condiciones' title='Leer los términos y condiciones'>
                <Typography textAlign='center'>Términos</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default SiteNavbar;
