import React from 'react';
import Head from 'next/head';

import {MainProps} from '@/model/props/main.props';

import SiteNavbar from '@/components/site-navbar';
import SiteFooter from '@/components/site-footer';
import ScrollToTop from '@/components/scroll-to-top';

const Layout = ({children, title, path, description}: MainProps): React.JSX.Element => {
  const year: number = new Date().getFullYear();

  return (
    <>
      <Head>
        <meta charSet='utf-8'/>
        <meta name='viewport' content='width=device-width, initial-scale=1, shrink-to-fit=no'/>
        <meta httpEquiv='X-UA-Compatible' content='IE=edge'/>

        {/* MAIN INFO */}
        <title>{title}</title>
        <link rel='shortlink' href={`https://golito.co${path}`}/>
        <link rel='canonical' href={`https://golito.co${path}`}/>

        {/* WEB PAGE MANIFEST */}
        <link rel='manifest' href='/manifest.json'/>

        {/* REGULAR ICONS */}
        <link rel='icon' type='image/x-icon' href='/icons/favicon.ico'/>
        <link rel='icon' type='image/png' sizes='16x16' href='/icons/icon-16x16.png'/>
        <link rel='icon' type='image/png' sizes='32x32' href='/icons/icon-32x32.png'/>
        <link rel='icon' type='image/png' sizes='36x36' href='/icons/icon-36x36.png'/>
        <link rel='icon' type='image/png' sizes='72x72' href='/icons/icon-72x72.png'/>
        <link rel='icon' type='image/png' sizes='96x96' href='/icons/icon-96x96.png'/>
        <link rel='icon' type='image/png' sizes='128x128' href='/icons/icon-128x128.png'/>
        <link rel='icon' type='image/png' sizes='144x144' href='/icons/icon-144x144.png'/>
        <link rel='icon' type='image/png' sizes='152x152' href='/icons/icon-152x152.png'/>
        <link rel='icon' type='image/png' sizes='192x192' href='/icons/icon-192x192.png'/>
        <link rel='icon' type='image/png' sizes='512x512' href='/icons/icon-512x512.png'/>

        {/* APPLE CONFIG */}
        <meta name='apple-mobile-web-app-title' content='Golito'/>
        <meta name='apple-mobile-web-app-capable' content='yes'/>
        <meta name='apple-mobile-web-app-status-bar-style' content='black'/>

        {/* APPLE TOUCH ICONS */}
        <link rel='apple-touch-icon' href='/icons/apple/apple-touch-icon.png'/>
        <link rel='apple-touch-icon' sizes='57x57' href='/icons/apple/apple-touch-icon-57x57.png'/>
        <link rel='apple-touch-icon' sizes='60x60' href='/icons/apple/apple-touch-icon-60x60.png'/>
        <link rel='apple-touch-icon' sizes='72x72' href='/icons/apple/apple-touch-icon-72x72.png'/>
        <link rel='apple-touch-icon' sizes='76x76' href='/icons/apple/apple-touch-icon-76x76.png'/>
        <link rel='apple-touch-icon' sizes='114x114' href='/icons/apple/apple-touch-icon-114x114.png'/>
        <link rel='apple-touch-icon' sizes='120x120' href='/icons/apple/apple-touch-icon-120x120.png'/>
        <link rel='apple-touch-icon' sizes='128x128' href='/icons/apple/apple-touch-icon-128x128.png'/>
        <link rel='apple-touch-icon' sizes='144x144' href='/icons/apple/apple-touch-icon-144x144.png'/>
        <link rel='apple-touch-icon' sizes='152x152' href='/icons/apple/apple-touch-icon-152x152.png'/>
        <link rel='apple-touch-icon' sizes='167x167' href='/icons/apple/apple-touch-icon-167x167.png'/>
        <link rel='apple-touch-icon' sizes='180x180' href='/icons/apple/apple-touch-icon-180x180.png'/>

        {/* APPLE SPLASH SCREEN ICONS */}
        <link rel='apple-touch-startup-image' href='/icons/apple/apple-splash-2048-2732.png' media='(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'/>
        <link rel='apple-touch-startup-image' href='/icons/apple/apple-splash-2732-2048.png' media='(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)'/>
        <link rel='apple-touch-startup-image' href='/icons/apple/apple-splash-1668-2388.png' media='(device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'/>
        <link rel='apple-touch-startup-image' href='/icons/apple/apple-splash-2388-1668.png' media='(device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)'/>
        <link rel='apple-touch-startup-image' href='/icons/apple/apple-splash-1536-2048.png' media='(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'/>
        <link rel='apple-touch-startup-image' href='/icons/apple/apple-splash-2048-1536.png' media='(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)'/>
        <link rel='apple-touch-startup-image' href='/icons/apple/apple-splash-1488-2266.png' media='(device-width: 744px) and (device-height: 1133px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'/>
        <link rel='apple-touch-startup-image' href='/icons/apple/apple-splash-2266-1488.png' media='(device-width: 744px) and (device-height: 1133px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)'/>
        <link rel='apple-touch-startup-image' href='/icons/apple/apple-splash-1640-2360.png' media='(device-width: 820px) and (device-height: 1180px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'/>
        <link rel='apple-touch-startup-image' href='/icons/apple/apple-splash-2360-1640.png' media='(device-width: 820px) and (device-height: 1180px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)'/>
        <link rel='apple-touch-startup-image' href='/icons/apple/apple-splash-1668-2224.png' media='(device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'/>
        <link rel='apple-touch-startup-image' href='/icons/apple/apple-splash-2224-1668.png' media='(device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)'/>
        <link rel='apple-touch-startup-image' href='/icons/apple/apple-splash-1620-2160.png' media='(device-width: 810px) and (device-height: 1080px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'/>
        <link rel='apple-touch-startup-image' href='/icons/apple/apple-splash-2160-1620.png' media='(device-width: 810px) and (device-height: 1080px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)'/>
        <link rel='apple-touch-startup-image' href='/icons/apple/apple-splash-1290-2796.png' media='(device-width: 430px) and (device-height: 932px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)'/>
        <link rel='apple-touch-startup-image' href='/icons/apple/apple-splash-2796-1290.png' media='(device-width: 430px) and (device-height: 932px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)'/>
        <link rel='apple-touch-startup-image' href='/icons/apple/apple-splash-1179-2556.png' media='(device-width: 393px) and (device-height: 852px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)'/>
        <link rel='apple-touch-startup-image' href='/icons/apple/apple-splash-2556-1179.png' media='(device-width: 393px) and (device-height: 852px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)'/>
        <link rel='apple-touch-startup-image' href='/icons/apple/apple-splash-1284-2778.png' media='(device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)'/>
        <link rel='apple-touch-startup-image' href='/icons/apple/apple-splash-2778-1284.png' media='(device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)'/>
        <link rel='apple-touch-startup-image' href='/icons/apple/apple-splash-1170-2532.png' media='(device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)'/>
        <link rel='apple-touch-startup-image' href='/icons/apple/apple-splash-2532-1170.png' media='(device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)'/>
        <link rel='apple-touch-startup-image' href='/icons/apple/apple-splash-1125-2436.png' media='(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)'/>
        <link rel='apple-touch-startup-image' href='/icons/apple/apple-splash-2436-1125.png' media='(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)'/>
        <link rel='apple-touch-startup-image' href='/icons/apple/apple-splash-1242-2688.png' media='(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)'/>
        <link rel='apple-touch-startup-image' href='/icons/apple/apple-splash-2688-1242.png' media='(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)'/>
        <link rel='apple-touch-startup-image' href='/icons/apple/apple-splash-828-1792.png' media='(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'/>
        <link rel='apple-touch-startup-image' href='/icons/apple/apple-splash-1792-828.png' media='(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)'/>
        <link rel='apple-touch-startup-image' href='/icons/apple/apple-splash-1242-2208.png' media='(device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)'/>
        <link rel='apple-touch-startup-image' href='/icons/apple/apple-splash-2208-1242.png' media='(device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)'/>
        <link rel='apple-touch-startup-image' href='/icons/apple/apple-splash-750-1334.png' media='(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'/>
        <link rel='apple-touch-startup-image' href='/icons/apple/apple-splash-1334-750.png' media='(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)'/>
        <link rel='apple-touch-startup-image' href='/icons/apple/apple-splash-640-1136.png' media='(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'/>
        <link rel='apple-touch-startup-image' href='/icons/apple/apple-splash-1136-640.png' media='(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)'/>

        {/* PAGE INFORMATION */}
        <meta name='application-name' content='Golito'/>
        <meta name='author' content='Cristiam Mercado'/>
        <meta name='description' content={description}/>
        <meta name='keywords' content='golito,fútbol,eventos,equipo,administrar,pagos'/>
        <meta name='theme-color' content='#FFC107'/>
        <meta name='color-scheme' content='dark'/>
        <meta name='robots' content='index,follow'/>
        <meta name='copyright' content={`Copyright © 2022 - ${year} Golito. Todos los derechos reservados`}/>
        <meta name='google-site-verification' content='rIH5EQ_GUcgTzJU0PiIkbWDu5g1y2WBFkwVm02f3QEM'/>

        {/* TWITTER CARD DATA */}
        <meta name='twitter:card' content='summary_large_image'/>
        <meta name='twitter:site' content='@golitoco'/>
        <meta name='twitter:creator' content='@golitoco'/>
        <meta name='twitter:title' content={title}/>
        <meta name='twitter:description' content={description}/>
        <meta name='twitter:image' content='https://golito.co/img/x.webp'/>
        <meta name='twitter:image:alt' content='Imagen de presentación de Golito'/>

        {/* OPEN GRAPH */}
        <meta property='og:type' content='website'/>
        <meta property='og:title' content={title}/>
        <meta property='og:description' content={description}/>
        <meta property='og:url' content='https://golito.co'/>
        <meta property='og:image' content='https://golito.co/img/og.webp'/>
        <meta property='og:image:secure_url' content='https://golito.co/img/og.webp'/>
        <meta property='og:image:type' content='image/webp'/>
        <meta property='og:image:width' content='600'/>
        <meta property='og:image:height' content='315'/>
        <meta property='og:image:alt' content='Imagen de presentación de Golito'/>
        <meta property='og:site_name' content='Golito'/>
        <meta property='og:locale' content='es_LA'/>
      </Head>
      <div id='app-top'/>
      <a className={'SkipLink'} href='#root' title='Saltar al contenido'>Saltar al contenido</a>
      <SiteNavbar/>
      <main id='root' style={{minHeight: '90vh'}}>
        {children}
      </main>
      <SiteFooter/>
      <ScrollToTop/>
    </>
  );
};

export default Layout;
